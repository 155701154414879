import RegisterForm from "./components/RegisterForm";

function App() {
  return (
    <main className="App">
      <RegisterForm />
    </main>
  );
}

export default App;
